import { SmallRow } from '@components/elements/DropsGrid/styled';
import { SvgIcon, Typography, TypographyVariant } from '@mui/material';
import download from '../../../../public/icons/download.svg';
import theme from '@styles/theme';
import React from 'react';

type PropsT = {
  get?: () => void;
  visible?: boolean;
  textVariant?: TypographyVariant;
};

const Download = ({
  get,
  visible = true,
  textVariant = 'subtitle2',
}: PropsT) => (
  <>
    {visible && (
      <SmallRow onClick={get}>
        <SvgIcon component={download} viewBox="0 0 18 18" sx={{ mr: 0.5 }} />
        <Typography
          variant={textVariant}
          sx={{
            fontWeight: 'bold',
            '-webkit-user-select': 'none',
            userSelect: 'none',
          }}
          color={theme.palette.primary.dark}
        >
          Pobierz
        </Typography>
      </SmallRow>
    )}
  </>
);

export default Download;
