import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Typography } from '@mui/material';
import theme from '@styles/theme';
import {
  BillPhoto,
  DropZoneContainer,
} from '@components/modules/DropZoneModals/styled';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';

const AddedFileName = styled(Typography)`
  margin-left: 24px;

  @media (max-width: 660px) {
    margin-left: 10px;
    font-size: 14px;
  }
`;

const LoaderContainer = styled('div')`
  position: absolute;
  background-color: rgba(220, 225, 219, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bolder = styled('span')`
  font-weight: bold;
`;

type PropsT = {
  setCompressedFile: (f: any) => void;
  compressedFile: any;
  setImageCompressionInProgress: (f: any) => void;
  imageCompressionInProgress: boolean;
  label: string;
  error?: string | null;
  fileTypes?: { [key: string]: string[] };
};

const types = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'application/pdf': ['.pdf'],
};

export const DropzoneComponent = ({
  setCompressedFile,
  compressedFile,
  label,
  imageCompressionInProgress,
  setImageCompressionInProgress,
  error,
  fileTypes = types,
}: PropsT) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: fileTypes,
      maxFiles: 1,
      onDrop: (files) =>
        handleImageCompress(
          files,
          isDragReject,
          (v: boolean) => setImageCompressionInProgress(v),
          (f: any) => setCompressedFile(f)
        ),
    });

  const handleImageCompress = async (
    file: any,
    isDragReject: boolean,
    setImageCompressionInProgress: (v: boolean) => void,
    setCompressedFile: (f: any) => void
  ) => {
    setCompressedFile(null);
    const imageFile = file[0];

    if (!isDragReject && imageFile !== undefined) {
      setImageCompressionInProgress(true);

      let fileSize = imageFile.size / 1024 / 1024;
      fileSize = Number(fileSize.toFixed(2));

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      if (fileSize > 1 && imageFile.type !== 'application/pdf') {
        try {
          const compressedFile = await imageCompression(imageFile, options);

          setCompressedFile(compressedFile);
          setImageCompressionInProgress(false);
        } catch (error) {
          console.log('An error occurred: ', error);
          setImageCompressionInProgress(false);
        }
      } else {
        setImageCompressionInProgress(false);
        if (fileSize <= 4) {
          setCompressedFile(imageFile);
        } else {
          toast.error('Możesz dodać plik o maksymalnej wielkości 4MB.');
        }
      }
    } else if (imageFile === undefined) {
      toast.error(
        'Próbujesz dodać plik w niewłaściwym formacie, spróbuj dodać zdjęcie lub plik pdf'
      );
    }
  };

  return (
    <BillPhoto>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 'bold' }}
        color={theme.palette.secondary.main}
      >
        {label}
      </Typography>
      <Box sx={{ position: 'relative', top: 0 }}>
        <DropZoneContainer
          error={error ? error : undefined}
          {...getRootProps({
            isFocused,
            isDragAccept,
            isDragReject,
          })}
        >
          <input {...getInputProps()} data-cy="dropzone_input" />
          {compressedFile ? (
            <AddedFileName variant="subtitle1">
              Dodany plik: <Bolder>{compressedFile.name}</Bolder>
            </AddedFileName>
          ) : (
            <p>Przeciągnij i upuść plik lub kliknij aby dodać</p>
          )}
        </DropZoneContainer>

        {imageCompressionInProgress && (
          <LoaderContainer>
            <CircularProgress data-cy="dropzone_loader" />
          </LoaderContainer>
        )}
      </Box>
    </BillPhoto>
  );
};
