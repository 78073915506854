import React, { useEffect, useRef, useState } from 'react';
import { Typography, SvgIcon, Box } from '@mui/material';

import {
  GridContainer,
  GridItem,
  ShortertItem,
  DisableOnMobile,
} from './styled';

import garbage from '../../../../public/icons/garbage.svg';

import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import { HistoryDropItemInterface } from '@redux/interfaces';
import { useDispatch } from 'react-redux';
import { getConfirmation, getInvoice } from '@redux/myExports/actions';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Download from '@components/elements/DropsGrid/Download';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

const StatusColumn = ({ status }: { status: string }) => {
  switch (status) {
    case 'DONE':
      return <CheckIcon sx={{ mr: 0.5, color: '#A8C300' }} key="DONE" />;
    case 'CANCELED':
      return <ClearIcon sx={{ mr: 0.5, color: '#F44336' }} key="CANCELED" />;
    case 'FILL':
      return (
        <LibraryAddRoundedIcon sx={{ mr: 0.5, color: '#578C22' }} key="FILL" />
      );
    case 'PARTIALLY_DONE':
      return (
        <AutorenewIcon sx={{ mr: 0.5, color: 'gray' }} key="PARTIALLY_DONE" />
      );
    case 'CREATED':
      return (
        <HourglassBottomIcon sx={{ mr: 0.5, color: '#B3C6C0' }} key="CREATED" />
      );
    default:
      return null;
  }
};

const SingleRecord = ({
  item,
  onClick,
  deleteRecord,
  reportOrOpenComplaint,
  uploadConfirmationModal,
}: {
  item: HistoryDropItemInterface;
  onClick: (item: HistoryDropItemInterface) => void;
  deleteRecord: (item: HistoryDropItemInterface) => void;
  reportOrOpenComplaint: (item: HistoryDropItemInterface) => void;
  uploadConfirmationModal: (item: HistoryDropItemInterface) => void;
}) => {
  const [name, setName] = useState('');

  const dispatch = useDispatch();
  const deleteRef = useRef(null);
  const invoiceRef = useRef(null);
  const confirmationRef = useRef(null);

  const shortName = name.length > 20 ? `${name.slice(0, 20)}...` : name;

  const formatDate = (date: string) => {
    const separator = date.indexOf('T');
    return date.slice(0, separator);
  };

  const isUnnamed = (name: string) => {
    if (name === 'Unnamed') {
      setName('Brak informacji');
    } else {
      setName(name);
    }
  };

  const clickToEdit = (event: any) => {
    if (
      deleteRef.current &&
      // @ts-ignore
      !deleteRef.current?.contains(event.target) &&
      invoiceRef.current &&
      // @ts-ignore
      !invoiceRef.current?.contains(event.target) &&
      confirmationRef.current &&
      // @ts-ignore
      !confirmationRef.current?.contains(event.target)
    ) {
      onClick(item);
    }
  };

  useEffect(() => {
    isUnnamed(item.sanitation_company_name);
  });

  return (
    <GridContainer onClick={(e: any) => clickToEdit(e)}>
      <ShortertItem width="50%" mobileWidth="40%">
        <Typography variant="subtitle2">{formatDate(item.date)}</Typography>
      </ShortertItem>

      <GridItem>
        <Typography variant="subtitle2">{shortName}</Typography>
      </GridItem>

      <ShortertItem width="40%" mobileWidth="20%">
        <Typography variant="subtitle2">
          {item.amount ? item.amount : '-'}
        </Typography>
      </ShortertItem>

      <DisableOnMobile>
        <Typography variant="subtitle2">
          {item.price ? `${item.price}zł` : '-'}
        </Typography>
      </DisableOnMobile>

      <DisableOnMobile width="65%">
        <StatusColumn status={item.status} />
      </DisableOnMobile>

      <DisableOnMobile ref={invoiceRef}>
        <Download
          get={() => dispatch(getInvoice(`${item.id}/`))}
          visible={item.has_invoice}
        />
      </DisableOnMobile>

      <DisableOnMobile ref={confirmationRef}>
        {item.has_confirmation ? (
          <Download
            get={() => {
              dispatch(getConfirmation(`${item.id}/`));
            }}
          />
        ) : (
          <AddToPhotosIcon
            sx={{ mr: 0.5, color: '#3498db' }}
            onClick={() => {
              uploadConfirmationModal(item);
            }}
          />
        )}
      </DisableOnMobile>

      <DisableOnMobile width="40%" sx={{ cursor: 'pointer' }} ref={deleteRef}>
        {item.added_by_user ? (
          <div onClick={() => deleteRecord(item)}>
            <SvgIcon component={garbage} viewBox="0 0 24 24" sx={{ mr: 0.5 }} />

            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              Usuń
            </Typography>
          </div>
        ) : item.status === 'DONE' ? (
          <Box
            onClick={() => reportOrOpenComplaint(item)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <QuestionAnswerIcon sx={{ mr: 0.5, color: '#3498db' }} />

            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              {item.has_reported_complaint
                ? 'Szczegóły reklamacji'
                : 'Zgłoś reklamację'}
            </Typography>
          </Box>
        ) : (
          <Typography>Brak</Typography>
        )}
      </DisableOnMobile>
    </GridContainer>
  );
};

export default SingleRecord;
