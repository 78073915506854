import UniversalModalLayout from '@components/layouts/UniversalModalLayout';
import ModalHeader from '@components/elements/ModalHeader';
import React, { useEffect, useState } from 'react';

import {
  ButtonWrapper,
  Description,
  RedStar,
} from '@components/modules/DropZoneModals/styled';
import { GradientButton } from '@styles/styled';
import { useDispatch, useSelector } from 'react-redux';
import { DropzoneComponent } from '@components/modules/DropZoneModals/DropzoneComponent';
import { Box, FormHelperText, Typography } from '@mui/material';
import theme from '@styles/theme';
import { uploadConfirmation } from '@redux/myExports/actions';
import { DropRecordModalsT } from '@customTypes/types';
import { MyExportsInterface, RootState } from '@redux/interfaces';

const ConfirmationModal = ({ itemToEdit, closeModal }: DropRecordModalsT) => {
  const [confirmationFileError, setConfirmationFileError] = useState<
    null | string
  >(null);
  const [compressedFile, setCompressedFile] = useState<File | null>(null);
  const [imageCompressionInProgress, setImageCompressionInProgress] =
    useState<boolean>(false);

  const { orderConfirmationLoading, confirmationAdded } = useSelector<
    RootState,
    MyExportsInterface
  >((state) => state.myExports);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const formData = new FormData();
    if (compressedFile && itemToEdit) {
      const blobFile = new File([compressedFile], compressedFile.name);
      formData.append('file', blobFile);
      dispatch(uploadConfirmation(`${itemToEdit.id}/`, formData));
    } else {
      setConfirmationFileError('Dodaj plik z potiwerdzeniem.');
    }
  };

  useEffect(() => {
    if (confirmationAdded) {
      closeModal();
    }
  }, [confirmationAdded, orderConfirmationLoading]);

  return (
    <UniversalModalLayout>
      <ModalHeader
        title="Dodawanie potwierdzenia wywozu"
        handleExit={closeModal}
      />
      <Description>Wgraj zdjęcie lub skan potwierdzenia</Description>
      <Box>
        <DropzoneComponent
          label="ZDJĘCIE KWITU LUB POTWIERDZENIA*"
          compressedFile={compressedFile}
          error={confirmationFileError}
          setCompressedFile={(f: any) => {
            setConfirmationFileError(null);
            setCompressedFile(f);
          }}
          imageCompressionInProgress={imageCompressionInProgress}
          setImageCompressionInProgress={setImageCompressionInProgress}
        />
        {confirmationFileError && (
          <FormHelperText error sx={{ pl: 2 }} data-cy="dropzone_error">
            {confirmationFileError}
          </FormHelperText>
        )}
        <Typography
          sx={{ pl: '10px', mt: 3, mb: 3, fontSize: '14px' }}
          color={theme.palette.secondary.main}
        >
          <RedStar /> Pola obowiązkowe
        </Typography>
      </Box>
      <ButtonWrapper>
        <GradientButton
          sx={{ m: 0 }}
          onClick={handleSubmit}
          loading={orderConfirmationLoading}
          disabled={orderConfirmationLoading || confirmationAdded}
          data-cy="submit_confirmation"
        >
          Dodaj potwierdzenie
        </GradientButton>
      </ButtonWrapper>
    </UniversalModalLayout>
  );
};

export default ConfirmationModal;
