import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Paper, SvgIcon } from '@mui/material';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import SecondStep from '@components/modules/OrderStepper/SecondStep';
import FirstStep from '@components/modules/OrderStepper/FirstStep';
import ThirdStep from '@components/modules/OrderStepper/ThirdStep';
import Summary from '@components/modules/OrderStepper/Summary';
import SecondStepNonApp from '@components/modules/OrderStepper/SecondStepNonApp';
import { initialValuesT } from '@components/modules/OrderStepper/types';
import ThirdStepNonApp from '@components/modules/OrderStepper/ThirdStepNonApp';
import validator from '@components/modules/OrderStepper/validator';
import SuccessInfo from '@components/modules/OrderStepper/SuccessInfo';
import CompleteBillingData from '@components/modules/OrderStepper/CompleteBillingData';
import MobileStepper from '@components/elements/MobileStepper';

import {
  ClientData,
  DashboardInterface,
  OrderInterface,
  PanoramaInterface,
  RootState,
  UserTanksInterface,
} from '@redux/interfaces';
import {
  getClientProperty,
  getTankDetails,
} from '@redux/userCesspools/actions';
import {
  askForDriverInCommune,
  cancelOrderProposition,
  getClientCompanies,
  resetDates,
} from '@redux/order/actions';

import { types } from '../../../analytics/types';
import {
  levelEndFA,
  levelStartFA,
  removeFromCartFA,
} from '../../../analytics/analyticFunctions';

import successfull from '../../../../public/icons/successfull.svg';
import exit from '../../../../public/icons/exit.svg';
import loadingAnimation from '../../../../public/lottie/loading.json';
import { WarningModal } from '@components/elements/CancelAddTankModal/WarningModal';
import { CesspoolTankSelector } from '@components/elements/CesspoolTankSelector';
import ContractLoaderContainer from '@components/elements/ContractLoaderContainer';

export const CenterContainer = styled(Container)`
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 0 60px 0;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  background-image: linear-gradient(
    120deg,
    rgba(87, 140, 34, 0.95),
    rgba(168, 195, 0, 0.95)
  );
`;

const Row = styled(Box)`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 24px;
  padding-left: 24px;

  @media (max-width: 660px) {
    padding-top: 18px;
    padding-left: 18px;
  }
`;

type Icon = {
  component: any;
};

const ExitIcon = styled(SvgIcon)<Icon>`
  font-size: 60px;

  &:hover {
    cursor: pointer;
  }
`;

const StepperWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 8px;
  padding-top: 8px;
  background-image: linear-gradient(to right, #f6f9fb, #fff);
  width: 100%;

  @media (max-width: 660px) {
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const initialValues: initialValuesT = {
  orderType: null,
  cost: '',
  costType: '',
  fullDate: null,
  paymentMethod: '',
  selectedTank: 0,
  driver: { id: null, appClient: null, phoneNum: null, name: null },
  estimatedCost: { estimated_low: 1, estimated: 1 },
  analyticsInfo: null,
  message: '',
  saveComment: true,
};

const OrderLayout = ({ close }: { close: () => void }) => {
  const [billingDataCompleted, setBillingDataCompleted] = useState(true);
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [selectedTank, setSelectedTank] = useState<number | null>(null);
  const [orderType, setOrderType] = useState<'NUM' | 'APP' | null>(null);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [isAskedAfterThreePM, setIsAskedAfterThreePM] =
    useState<boolean>(false);
  const [warningModalVisible, setWarningModalVisible] =
    useState<boolean>(false);
  const [currentDriver, setCurrentDriver] = useState<PanoramaInterface>({
    id: 0,
    phone_nb: '',
    name: '',
  });

  const dispatch = useDispatch();

  const {
    currentTank: { ask_order: tankAskOrder },
  } = useSelector<RootState, DashboardInterface>((state) => state.dashboard);

  const { billingData } = useSelector<RootState, ClientData>(
    (state) => state.contact
  );

  const {
    successfullyAsked,
    loading: orderLoading,
    askOrder,
    askOrderLoading,
    loadingContract,
  } = useSelector<RootState, OrderInterface>((state) => state.order);

  const { clientProperty, selectedTankId, currentTank } = useSelector<
    RootState,
    UserTanksInterface
  >((state) => state.userCesspools);

  useEffect(() => {
    dispatch(getClientProperty());
    levelEndFA(types.STEP_0, true);
    levelStartFA(types.STEP_1);
  }, []);

  useEffect(() => {
    if (selectedTank) dispatch(getTankDetails(`${selectedTank}/`));
  }, [selectedTank]);

  useEffect(() => {
    if (selectedTankId && clientProperty) {
      setPropertyWithId(selectedTankId);
    }
  }, [selectedTankId, clientProperty]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validator,
    onSubmit: () => {},
  });

  const handleChangeStep = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const setPropertyWithId = (id: number) => {
    const currentTank = clientProperty.find((item) => item.id === id);

    if (currentTank && !currentTank.has_order && !currentTank.has_debt) {
      setSelectedTank(currentTank.id);
      formik.setFieldValue('selectedTank', currentTank.id);

      if (!tankAskOrder) {
        dispatch(getClientCompanies(`${currentTank.id}/`));
      }
    } else {
      const freeProperties = clientProperty.filter(
        (item) => !item.has_order && !item.has_debt
      );

      if (freeProperties.length) {
        setSelectedTank(freeProperties[0].id);
        formik.setFieldValue('selectedTank', freeProperties[0].id);
        dispatch(getClientCompanies(`${freeProperties[0].id}/`));
      }
    }
  };

  const orderByNumOrApp = (
    type: 'NUM' | 'APP',
    driver?: PanoramaInterface | null
  ) => {
    setOrderType(type);
    formik.setFieldValue('orderType', type);
    formik.setFieldValue('driver', driver);

    if (driver) setCurrentDriver(driver);
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleChangeStepBack = () => {
    if (
      askOrder &&
      askOrder?.client_property === selectedTankId &&
      currentStep === 2
    ) {
      handleCloseModal();
    } else {
      setCurrentStep((prevState) => prevState - 1);
    }
  };

  const analyticsLogOnClose = () => {
    const { analyticsInfo } = formik.values;

    switch (orderType) {
      case 'APP':
        levelEndFA(types.LEVEL_NAME_APP[currentStep], false);
        currentStep === 3 && analyticsInfo && removeFromCartFA(analyticsInfo);
        break;
      case 'NUM':
        levelEndFA(types.LEVEL_NAME_NUM[currentStep], false);
        break;
      default:
        if (currentStep === 0) {
          levelEndFA(types.STEP_1, false);
        }
    }
  };

  const askForDriver = () => {
    const { fullDate } = formik.values;

    if (fullDate) {
      const date =
        fullDate.getFullYear() +
        '-' +
        (fullDate.getMonth() + 1) +
        '-' +
        fullDate.getDate();

      let correctDate = date;
      correctDate = correctDate.replaceAll('.', '-');

      const hour = fullDate.getHours();
      const minutes = fullDate.getMinutes();

      setIsAskedAfterThreePM(hour > 15 || (hour === 15 && minutes >= 30));
      dispatch(
        askForDriverInCommune(`${selectedTankId}/`, {
          date: correctDate,
        })
      );
    }
  };

  const handleCloseModal = () => {
    close();
    analyticsLogOnClose();
    setCurrentStep(-1);
    dispatch(resetDates());
  };

  useEffect(() => {
    setBillingDataCompleted(Boolean(billingData?.full_name));
  }, []);

  useEffect(() => {
    if (successfullyAsked) {
      setIsFinished(true);
    }
  }, [successfullyAsked, orderLoading]);

  useEffect(() => {
    if (currentTank?.cesspool_name.length) {
      if (askOrder && askOrder?.client_property === selectedTankId) {
        setCurrentStep(2);
        setOrderType('APP');
        formik.setFieldValue('driver', askOrder.company);
        formik.setFieldValue('estimatedCost', askOrder.estimated_cost);
        formik.setFieldValue('orderType', 'APP');
        if (askOrder.planned_date) {
          formik.setFieldValue('fullDate', new Date(askOrder.planned_date));
        }
      } else {
        setCurrentStep(0);
      }
    }
  }, [askOrder, askOrderLoading, selectedTank, selectedTankId, currentTank]);

  return (
    <CenterContainer maxWidth={false}>
      {warningModalVisible && (
        <WarningModal
          warningMessage="Na pewno chcesz odwołać zamówiony wywóz?"
          backButtonText="Wstecz"
          cancelButtonText="Odwołaj wywóz"
          exit={() => setWarningModalVisible(false)}
          cancelAdd={() => {
            handleCloseModal();
            dispatch(cancelOrderProposition(`${askOrder.id}/`));
            setWarningModalVisible(false);
          }}
          loading={orderLoading}
        />
      )}
      <Container maxWidth="md">
        <Paper
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: '25px',
            paddingBottom: '30px',
            boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
          }}
        >
          {loadingContract && <ContractLoaderContainer />}
          {!isFinished ? (
            <>
              <Row
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography variant="h4" sx={{ display: 'inline-block' }}>
                    Zamów wywóz
                  </Typography>
                  <ExitIcon
                    onClick={handleCloseModal}
                    component={exit}
                    viewBox="0 0 64 36.77"
                  />
                </Box>
                <CesspoolTankSelector
                  formik={formik}
                  setSelectedTank={setSelectedTank}
                  selectedTank={selectedTank}
                  currentStep={currentStep}
                />
              </Row>

              {!orderLoading && !askOrderLoading && currentStep >= 0 && (
                <StepperWrapper>
                  <MobileStepper
                    orderType={orderType}
                    currentStep={currentStep}
                  />
                </StepperWrapper>
              )}
              {currentStep === 0 && (
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <FirstStep checkType={orderByNumOrApp} />
                </Box>
              )}
              {orderType === 'APP' && (
                <>
                  {currentStep === 1 && (
                    <SecondStep
                      nextStep={() => {
                        if (formik.values.driver?.id === -1) {
                          askForDriver();
                        } else {
                          handleChangeStep();
                        }
                      }}
                      prevStep={handleChangeStepBack}
                      formik={formik}
                      appOrder={true}
                    />
                  )}
                  {currentStep === 2 && (
                    <ThirdStep
                      nextStep={handleChangeStep}
                      prevStep={handleChangeStepBack}
                      cancelProposition={() => {
                        setWarningModalVisible(true);
                      }}
                      formik={formik}
                    />
                  )}
                  {!billingDataCompleted && currentStep === 3 && (
                    <CompleteBillingData
                      nextStep={handleChangeStep}
                      prevStep={handleChangeStepBack}
                    />
                  )}
                  {((!billingDataCompleted && currentStep === 4) ||
                    (billingDataCompleted && currentStep === 3)) && (
                    <Summary
                      prevStep={handleChangeStepBack}
                      nextStep={() => {
                        handleChangeStep();
                        setIsFinished(true);
                      }}
                      formik={formik}
                    />
                  )}
                </>
              )}
              {orderType === 'NUM' && (
                <>
                  {currentStep === 1 && (
                    <SecondStepNonApp
                      driver={currentDriver}
                      close={() => {
                        close();
                        setCurrentStep(0);
                      }}
                      nextStep={handleChangeStep}
                    />
                  )}
                  {currentStep === 2 && (
                    <SecondStep
                      nextStep={handleChangeStep}
                      prevStep={handleChangeStepBack}
                      formik={formik}
                      appOrder={false}
                    />
                  )}
                  {currentStep === 3 && (
                    <ThirdStepNonApp
                      driver={currentDriver}
                      prevStep={handleChangeStepBack}
                      nextStep={handleChangeStep}
                      formik={formik}
                    />
                  )}
                  {currentStep === 4 && (
                    <Summary
                      nonApp={true}
                      prevStep={handleChangeStepBack}
                      nextStep={() => {
                        handleChangeStep();
                        setIsFinished(true);
                      }}
                      formik={formik}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <SuccessInfo
              close={close}
              title={
                successfullyAsked
                  ? 'Twoje zapytanie o możliwość wywozu zostało przyjęte'
                  : 'Twoje zamówienie zostało potwierdzone.'
              }
              message={
                successfullyAsked
                  ? `Nasz zespół kontaktuje się teraz z dostępnymi operatorami aby znaleźć pasujący termin wywozu. Powiadomimy Cię o propozycji zamówienia najwcześniej w ${
                      isAskedAfterThreePM
                        ? 'ciągu kolejnych 2 dni roboczych.'
                        : 'kolejnym dniu roboczym.'
                    }`
                  : 'Status wywozu oraz płatności możesz sprawdzić w zakładce Moje wywozy.'
              }
              Icon={
                successfullyAsked ? (
                  <div>
                    <Lottie
                      animationData={loadingAnimation}
                      loop={true}
                      style={{ height: '200px', marginBottom: '-30px' }}
                    />
                  </div>
                ) : (
                  <SvgIcon
                    component={successfull}
                    viewBox="0 0 16.221 16.002"
                    sx={{ fontSize: '7rem' }}
                  />
                )
              }
            />
          )}
        </Paper>
      </Container>
    </CenterContainer>
  );
};

export default OrderLayout;
