import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  BorderButton,
  ErrorBorderButton,
  GradientButton,
  OrderRow,
} from '@styles/styled';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  CurrentTankInterface,
  OrderInterface,
  ProgressivePriceInterface,
  RootState,
  UserTanksInterface,
} from '@redux/interfaces';
import {
  addToCartFA,
  levelEndFA,
  levelStartFA,
} from '../../../analytics/analyticFunctions';
import { types } from '../../../analytics/types';
import { PriceList } from '@components/elements/PriceListThirdStep';

const CompanyName = styled(Box)`
  font-size: 32px;
  width: 70%;
  text-align: center;
  margin: -30px auto 20px auto;

  @media (max-width: 660px) {
    font-size: 26px;
    width: 100%;
  }
`;

const SecondWrapper = styled(Box)`
  padding: 40px 0;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 660px) {
    width: 100%;
    padding-top: 0;
  }
`;

const Wrapper = styled(Box)`
  width: 100%;
  padding: 40px;

  @media (max-width: 660px) {
    padding: 40px 10px;
  }
`;

const AskOrderButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

type ThirdStepType = {
  nextStep: () => void;
  prevStep: () => void;
  cancelProposition: () => void;
  formik: any;
};

export type PriceListValuesT = {
  firstLineTitle?: string;
  firstLineValue?: string;
  secondLineTitle?: string;
  secondLineValue?: string;
  minimalPrice?: string;
  additionalInfo?: string;
};

const ThirdStep = ({
  nextStep,
  prevStep,
  cancelProposition,
  formik,
}: ThirdStepType) => {
  const [pricelistValues, setPriceListValus] = useState<PriceListValuesT>({});
  const [analyticsValues, setAnalyticsValues] = useState<any>([]);

  const { priceList, askOrder } = useSelector<RootState, OrderInterface>(
    (state) => state.order
  );

  const { currentTank, selectedTankId } = useSelector<
    RootState,
    UserTanksInterface
  >((state) => state.userCesspools);

  const progressiveCostAndSize = () => {
    const tankSize = Number(
      currentTank.cesspoolfeatures?.cesspool_size ||
        currentTank.ossffeatures?.cesspool_size
    );

    let currentPrice: number = 0;
    let cost: ProgressivePriceInterface | undefined;

    if (Array.isArray(priceList.prices)) {
      cost = priceList.prices.find(
        (item) =>
          item.max_capacity >= tankSize && item.min_capacity + 0.001 <= tankSize
      );
    }
    if (cost?.price) {
      currentPrice = cost.price;
    }

    return { tankSize, currentPrice };
  };

  const showEstimated = (currentTank: CurrentTankInterface): string => {
    const { estimatedCost } = formik.values;
    const discount = currentTank.has_discount ? 10 : 0;
    let totalCost =
      estimatedCost.estimated - discount < 0
        ? estimatedCost.estimated
        : estimatedCost.estimated - discount;

    if (priceList.extra.length) {
      totalCost = priceList.extra.reduce((acc, service) => {
        return service.mandatory ? acc + service.value : acc;
      }, totalCost);
    }
    switch (priceList.type_code) {
      case 'TYPE_SERVICE':
      case 'TYPE_PROGRESSIVE':
      case 'TYPE_LINEAR':
        const estimatedLow =
          estimatedCost.estimated_low - discount > 0
            ? estimatedCost.estimated_low - discount
            : 0;
        return estimatedCost.estimated_low === estimatedCost.estimated
          ? totalCost.toFixed(2) + ' zł'
          : estimatedLow.toFixed(2) + ' - ' + totalCost.toFixed(2) + ' zł';

      case 'TYPE_ZERO':
      default:
        return '0 zł';
    }
  };

  useEffect(() => {
    setPriceListValues();
  }, [priceList]);

  const setPriceListValues = () => {
    switch (priceList.type_code) {
      case 'TYPE_SERVICE':
        const { barrel_size, price } = priceList.prices;
        setAnalyticsValues([
          {
            item_name: 'cleanup_cost',
            price: price,
          },
          {
            item_name: 'max_barrel_size',
            price: barrel_size,
          },
        ]);

        setPriceListValus({
          firstLineTitle: 'Opłata za oczyszczanie ścieków',
          firstLineValue: price + ' zł/kurs',
          secondLineTitle: 'Wywóz nieczystości (max)',
          secondLineValue: barrel_size + ' m³',
        });
        break;

      case 'TYPE_LINEAR':
        const {
          start_price_name,
          start_price,
          minimal_price,
          price_per_cubic_meter,
          start_price_type_code,
        } = priceList.prices;

        setAnalyticsValues([
          {
            item_name: 'price_per_cubic_meter',
            price: price_per_cubic_meter,
          },
          {
            item_name: 'minimal_price',
            price: minimal_price,
          },
          ...(start_price_name
            ? [
                {
                  item_name: 'start_price',
                  price: start_price,
                },
              ]
            : []),
        ]);

        setPriceListValus({
          firstLineTitle: start_price_name,
          firstLineValue:
            start_price_type_code === 'START_PRICE_PER_ORDER'
              ? start_price + ' zł'
              : start_price + ' zł/m³',
          secondLineTitle: 'Koszt nieczystości',
          secondLineValue: price_per_cubic_meter + ' zł/m³',
          ...(minimal_price &&
            minimal_price > 0 && {
              minimalPrice: `Jeśli wartość zamówienia będzie niższa, niż kwota minimalna, koszt wywozu zostanie zrównany do kwoty minimalnej.`,
            }),
        });
        break;

      case 'TYPE_PROGRESSIVE':
        const { currentPrice, tankSize } = progressiveCostAndSize();

        setAnalyticsValues([
          {
            item_name: 'cesspool_size',
            price: tankSize,
          },
          {
            item_name: 'cost_per_cesspool',
            price: currentPrice,
          },
        ]);

        setPriceListValus({
          additionalInfo: `Koszt obsługi wywozu nieczystości z ${
            currentTank.cesspoolfeatures?.cesspool_size
              ? 'bezodpływowego zbiornika asenizacyjnego'
              : 'przydomowej oczyszczalni ścieków'
          }
                       o wielkości ${tankSize} m³ u
                      wybranego Operatora jest stały i wynosi ${currentPrice} zł. Nie jest on
                      uzależniony od rzeczywistej ilości wywiezionych
                      nieczystości.`,
        });
        break;

      case 'TYPE_ZERO':
        setAnalyticsValues([
          {
            item_name: 'type_zero',
            price: 0,
          },
        ]);
        setPriceListValus({
          additionalInfo:
            'Przy zamawianiu wywozu u tego Operatora, w aplikacji nie ponosisz z tego tytułu żadnych dodatkowych kosztów.',
        });
        break;

      default:
        setPriceListValus({
          additionalInfo: 'Brak informacji na temat tego cennika',
        });
        break;
    }
  };

  const logAnalytics = () => {
    levelEndFA(types.LEVEL_NAME_APP[2], true);
    levelStartFA(types.LEVEL_NAME_APP[3]);
    const {
      estimatedCost: { estimated },
    } = formik.values;

    const addToCartData = {
      value: estimated,
      currency: 'PLN',
      items: [
        ...analyticsValues,
        {
          item_name: 'pricelist_type',
          price: priceList.type_code,
        },
      ],
    };

    formik.setFieldValue('analyticsInfo', addToCartData);
    addToCartFA(addToCartData);
  };

  const handlePrevStep = () => {
    prevStep();
    levelEndFA(types.LEVEL_NAME_APP[2], false);
  };

  const handleNextStep = () => {
    formik.setFieldValue('cost', showEstimated(currentTank));
    logAnalytics();
    nextStep();
  };

  return (
    <Wrapper sx={{ p: 5, width: '100%' }}>
      <SecondWrapper>
        <CompanyName>{formik.values.driver.name}</CompanyName>
        <Typography
          sx={{ textAlign: 'left', fontWeight: 'bold', mb: 3 }}
          color="#506266"
        >
          Cennik
        </Typography>
        <PriceList
          data={pricelistValues}
          showEstimated={() => showEstimated(currentTank)}
        />
      </SecondWrapper>
      <OrderRow sx={{ width: '100%' }}>
        <AskOrderButtonsWrapper>
          <BorderButton onClick={() => handlePrevStep()}>Wstecz</BorderButton>
          {askOrder && askOrder.client_property === selectedTankId && (
            <ErrorBorderButton onClick={cancelProposition}>
              Anuluj propozycję
            </ErrorBorderButton>
          )}
        </AskOrderButtonsWrapper>
        <GradientButton sx={{ margin: 0 }} onClick={() => handleNextStep()}>
          Dalej
        </GradientButton>
      </OrderRow>
    </Wrapper>
  );
};

export default ThirdStep;
