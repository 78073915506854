'use client';

import { types } from './types';
import {
  ActiveOrdersInterface,
  InvoiceUnpaidInterface,
} from '@redux/interfaces';
import { store } from '@redux/store';

export const setInvoiceToPay = (
  data: ActiveOrdersInterface | InvoiceUnpaidInterface
) => {
  return {
    type: types.SET_INVOICE_TO_PAY,
    data,
  };
};

export const getClientCompanies = (id: string) => {
  return {
    type: types.GET_CLIENT_COMPANIES_REQUEST,
    id,
  };
};

export const getCompanyDates = (id: string) => {
  return {
    type: types.GET_COMPANY_DATES_REQUEST,
    id,
  };
};

export const resetDates = () => {
  return {
    type: types.GET_COMPANY_DATES_RESET,
  };
};

export const addNewOrder = (
  id: string,
  data: { date: string; company: number }
) => {
  return {
    method: 'POST',
    type: types.ADD_NEW_APP_ORDER_REQUEST,
    id,
    data,
  };
};

export const resetOrderAdded = () => {
  return {
    type: types.RESET_ORDER_ADDED,
  };
};

export const resetOrderDeleteAfterFail = () => {
  return {
    type: types.RESET_ORDER_DELETE,
  };
};

export const resetOrderIdToDelete = () => {
  return {
    type: types.RESET_ORDER_ID_TO_DELETE,
  };
};

export const orderIdToDelete = (id: number) => {
  return {
    type: types.SET_ORDER_ID_TO_DELETE,
    payload: id,
  };
};

export const deleteOrder = () => {
  const { orderIdToDelete } = store.getState().order;
  const id = `${orderIdToDelete}/`;

  return {
    type: types.DELETE_ORDER_REQUEST,
    method: 'DELETE',
    id,
  };
};

export const getContractWithDriver = (id: string) => {
  return {
    type: types.GET_DRIVER_CONTRACT_REQUEST,
    method: 'GET',
    id,
  };
};

export const askForDriverInCommune = (id: string, data: { date: string }) => {
  return {
    type: types.ASK_FOR_DRIVER_IN_COMMUNE_REQUEST,
    id,
    data,
  };
};

export const getOrderPropositionDetails = (id: string) => {
  return {
    type: types.GET_ORDER_PROPOSITION_DETAILS_REQUEST,
    id,
  };
};

export const acceptOrderProposition = (
  id: string,
  data: { comment: string }
) => {
  return {
    type: types.ACCEPT_ORDER_PROPOSITION_REQUEST,
    id,
    data,
  };
};

export const cancelOrderProposition = (id: string) => {
  return {
    type: types.CANCEL_ORDER_PROPOSITION_REQUEST,
    id,
  };
};
