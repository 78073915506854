import React, { useEffect, useState } from 'react';
import { Box, FormLabel, Switch, TextField, Typography } from '@mui/material';
import theme from '@styles/theme';
import useWindowDimensions from '@hooks/useWindowDimension';
import { useSelector } from 'react-redux';
import { OrderInterface, RootState } from '@redux/interfaces';
import { styled } from '@mui/material/styles';

const SwitchWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  justify-content: flex-start;
  flex-direction: row;

  @media (max-width: 660px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

const MessageForDriver = ({ formik }: any) => {
  const [visible, setVisible] = useState(false);
  const [saveComment, setSaveComment] = useState(true);

  const { width } = useWindowDimensions();

  const { savedComment } = useSelector<RootState, OrderInterface>(
    (state) => state.order
  );

  useEffect(() => {
    setVisible(width > 660);
  }, [width]);

  useEffect(() => {
    if (savedComment) {
      formik.setFieldValue('message', savedComment);
      setVisible(true);
    }
  }, []);

  return (
    <Box>
      {width <= 660 && (
        <SwitchWrapper
          sx={{
            borderBottom: '2px solid #B3C6C0',
          }}
        >
          <Switch
            id="message"
            name="message"
            color="primary"
            checked={visible}
            onChange={() => setVisible((prevState: any) => !prevState)}
            data-cy="message"
          />
          <FormLabel>Dodatkowe informacje dla kierowcy</FormLabel>
        </SwitchWrapper>
      )}
      {visible && (
        <Box
          sx={{
            my: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            color={theme.palette.secondary.main}
          >
            DODATKOWA INFORMACJE DLA KIEROWCY
          </Typography>
          <TextField
            multiline
            placeholder="Wprowadź treść informacji dla kierowcy..."
            minRows={3}
            fullWidth
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            inputProps={{ maxlength: 600, 'data-cy': 'message' }}
            name="message"
            id="message"
            InputProps={{
              endAdornment: (
                <Typography color="gray">
                  {formik.values.message.length}/600
                </Typography>
              ),
            }}
          />
          <SwitchWrapper>
            <Switch
              id="save_comment"
              name="save_comment"
              color="primary"
              checked={saveComment}
              onChange={() => {
                setSaveComment((prevState: any) => {
                  formik.setFieldValue('saveComment', !prevState);
                  return !prevState;
                });
              }}
              data-cy="save_comment"
            />
            <FormLabel style={{ color: '#506266' }}>
              Zapisz i zaproponuj treść komentarza przy kolejnych zamówieniach
            </FormLabel>
          </SwitchWrapper>
        </Box>
      )}
    </Box>
  );
};

export default MessageForDriver;
