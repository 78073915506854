import React from 'react';
import { Typography } from '@mui/material';

import {
  GridContainer,
  GridItem,
  ShortertItem,
  DisableOnMobile,
} from './styled';

const Title = () => {
  return (
    <GridContainer>
      <ShortertItem width="50%" mobileWidth="40%">
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Data realizacji
        </Typography>
      </ShortertItem>

      <GridItem>
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Operator
        </Typography>
      </GridItem>

      <ShortertItem width="40%" mobileWidth="20%">
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Ilość [m³]
        </Typography>
      </ShortertItem>

      <DisableOnMobile>
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Koszt usługi
        </Typography>
      </DisableOnMobile>

      <DisableOnMobile width="65%">
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Status
        </Typography>
      </DisableOnMobile>

      <DisableOnMobile>
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Faktura VAT
        </Typography>
      </DisableOnMobile>

      <DisableOnMobile>
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Potwierdzenie
        </Typography>
      </DisableOnMobile>

      <DisableOnMobile width="40%">
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Opcje
        </Typography>
      </DisableOnMobile>
    </GridContainer>
  );
};

export default Title;
